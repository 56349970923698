<template>
  <div>
    <v-tabs v-model="tab_details">
        <v-tab dense :value="0">BODAS</v-tab>
        <v-tab dense :value="1">COMUNIONES</v-tab>
        <v-tab dense :value="2">CORPORATIVOS</v-tab>
        <v-tab dense :value="3">EVENTOS</v-tab>
      </v-tabs>
      <v-tabs-items
        style="font-size: 0.9rem;flex-grow: 1;"
        class="py-1"
        v-model="tab_details"
      >
      <v-tab-item :value="0">
        <ChartBoda/>
      </v-tab-item>
      <v-tab-item :value="1">
        <ChartComunion/>
      </v-tab-item>
      <v-tab-item :value="2">
        <ChartCorporativo/>
      </v-tab-item>
      <v-tab-item :value="3">
        <ChartEvento/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  components: {
    ChartBoda: () => import("../components/ChartBoda.vue"),
    ChartComunion: () => import("../components/ChartComunion.vue"),
    ChartCorporativo: () => import("../components/ChartCorporativo.vue"),
    ChartEvento: () => import("../components/ChartEvento.vue"),
  },
  data(){
    return {
      value: 0,
      tab_details: 0,
    }
  },
};
</script>